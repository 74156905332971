<template>
  <div class="page-box box">
    <van-form
      class="form-box"
    >
      <van-field
        v-model="form.childName"
        name="学员姓名"
        label="学员姓名"
        input-align="right"
        required
        placeholder="学员姓名"
        :rules="[{ required: true, message: '请填写学员姓名' }]"
      />
      <van-field
        input-align="right"
        readonly
        clickable
        required
        name="datetimePicker"
        :value="getTime(form.birthDay)"
        label="学员出生日期"
        placeholder="点击选择时间"
        @click="showPickerTime = true"
      />
      <van-popup
        v-model="showPickerTime"
        position="bottom"
      >
        <van-datetime-picker
          v-model="form.birthDay"
          type="date"
          title="选择出生日期"
          :columns-order="['year', 'month', 'day']"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirm"
          @cancel="showPickerTime = false"
        />
      </van-popup>
      <!-- <van-field
        v-model="form.name"
        name="关系"
        label="关系"
        required
        placeholder="输入与学员关系"
      /> -->
      <van-field
        readonly
        input-align="right"
        required
        clickable
        name="picker"
        :value="relationObj[form.relation]"
        label="关系"
        placeholder="选择与学员关系"
        :rules="[{ required: true, message: '请选择关系' }]"
        @click="showPicker = true"
      />
      <van-popup
        v-model="showPicker"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="relationArr"
          @confirm="onConfirmB"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="form.idCardNo"
        required
        input-align="right"
        name="身份证"
        label="学员身份证"
        placeholder="身份证号码"
        :rules="[{ required: true, message: '请填写身份证号码' }]"
      />
    </van-form>

    <van-button
      class="_btn"
      color="#51B464"
      round
      type="info"
      @click="onSubmit"
    >
      添加
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'Student',
  components: {
  },
  data () {
    return {
      form: {
        birthDay: '',
        childName: '',
        idCardNo: '',
        relation: '1',
      },
      relationObj: {
        1: '父母',
        2: '亲属',
        3: '其他'
      },
      relationArr: [
        {text: '父母', key: '1'},
        {text: '亲属', key: '2'},
        {text: '其他', key: '3'}
      ],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      showPicker: false,
      showPickerTime: false
    }
  },
  computed: {
  },
  created () {
    this.childNo = this.$route.query.id || ''
    if (this.childNo) {
      let info = JSON.parse(window.localStorage.getItem('studentItem') || '{}')
      console.log(info, 'info')
      info.birthDay = new Date(info.birthDay)
      this.form = info
    }
  },
  methods: {
    initData () {
      this.form = {
        birthDay: '',
        childName: '',
        idCardNo: '',
        relation: '1',
      }
    },
    getTime () {
      if (this.form.birthDay) {
        return this.getTimeStr(this.form.birthDay.getTime(), 'day')
      }
      return ''
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    onConfirm(time) {
      this.form.birthDay = time;
      this.showPickerTime = false;
    },
    onConfirmB (e) {
      console.log(e, 'e')
      this.form.relation = e.key;
      this.showPicker = false;
    },
    async onSubmit(values) {
      let url = 'childAdd'
      let id = this.$route.query.id
      if (id) {
        this.form.childNo = id
        url = 'childEdit'
      }
      let data = this.$deepClone(this.form)
      data.birthDay = this.getTimeStr(data.birthDay.getTime(), 'day')
      this.$toast.loading({
        message: '加载中',
        forbidClick: true
      });
      let res = await this.$http.userApi[url](data)
      if (res.code === 200) {
        this.$toast.success((id ? '编辑' : '新增') + '成功')
        setTimeout(() => {
          this.$router.back()
        }, 1500)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-top: 10px;
  .form-box {
    width: 100%;
  }
  ._btn {
    width: 345px;
    height: 40px;
    display: block;
    margin: 50px auto 0;
  }

}
</style>
